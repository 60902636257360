import React from 'react'
import PropTypes from 'prop-types'
import './Box.scss'

export default class Box extends React.Component {

  render() {
    const {
      children
    } = this.props
    return (
      <div className="box">
        <article className="media">
          <div className="media-content">
            <div className="content">
              {children}
            </div>
          </div>
        </article>
      </div>
    )
  }
}
