import React from 'react';
import OnVisible, { setDefaultProps } from 'react-on-visible';

import './FadeEffect.scss'

setDefaultProps({
    bounce: false,
    visibleClassName: 'appear',
    percent: 5
});

export default class FadeEffect extends React.Component {

  render() {
    const { children, className } = this.props;
    return (
      <OnVisible
      className={`${className} fade-effect`}
      bounce={true}

      >
        {children}
      </OnVisible>
    )
  }
}
