import React from 'react'
import PropTypes from 'prop-types'
import './Card.scss'

export default class Card extends React.Component {

  render() {
    const {
      children, item, backgroundColor
    } = this.props

    return (
      <div className="card" style={{backgroundColor}}>
        {item.image && (
          <div className="card-image">
            <figure className="image is-4by3">
              <img src={item?.image?.childImageSharp?.fluid?.src} alt={item.title} />
            </figure>
          </div>
        )}
        <div className="card-content">
          <div className="content">
            <p className="has-text-centered">
              <strong style={{marginRight: '12px'}}>{item.name}</strong><small>{item.price}</small>
              <br /><br />
              {item.description}
            </p>
            {children}
          </div>
        </div>
      </div>
    )
  }
}
