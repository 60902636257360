import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import Home from '../Home'
import Box from '../../components/Box'
import Button from '../../components/Button'
import Card from '../../components/Card'
import FadeEffect from '../../components/FadeEffect'
import Footer from '../../components/Footer'
import Prices from '../../components/Prices'
import Section from '../../components/Section'

import './MenuContainer.scss'

const menuItems = [
  { key: 'meats', label: 'Meats', path: '/menus/meats' },
  { key: 'sides', label: 'Included sides', path: '/menus/sides' },
  { key: 'desserts', label: 'Desserts', path: '/menus/desserts' },
  { key: 'drinks', label: 'Drinks', path: '/menus/drinks' },
]

const MenuContainer = ({ classNames, color, headings, menuType, sections = [] }) => {
  const slides = [
    {
      title: headings,
      showButton: false,
    },
  ];

  return (
    <div className="content">
      <Home classNames={classNames} menu slides={slides} />
      <Section name="menus" className={`wood-background is-${menuType}-menu`} style={{marginTop:"12px"}}>
        <div className="container wood-background">
          <div className="columns is-desktop">
            <div className="column is-one-third side-menu">
              {menuItems.map((item, index) => (
                <Link
                  activeClassName="is-active"
                  to={item.path}
                  key={`menu-${index}`}
                  replace
                  className={`button is-danger has-shadow`}
                >
                  {item.label}
                </Link>
              ))}
              <Prices className="is-hidden-mobile" />
            </div>
            <div className="column">
                {sections.map((section, i) => (
                  <div key={i} className="section-menu-items multi">
                    <div className="columns is-multiline" style={{paddingTop: "1rem"}}>
                      <div className="column is-full-width">
                        <h1>{section.name}</h1>
                        <div style={{ whiteSpace: 'pre-line' }}>
                          {section?.description}
                        </div>
                      </div>
                    </div>
                    <div className="columns is-multiline" style={{paddingTop: "1rem", paddingBottom: "1rem"}}>
                      {section.items.map((item, i) => (
                        <FadeEffect key={`card-${i}`} className="column is-half">
                          <Card item={item} backgroundColor={color} />
                        </FadeEffect>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Section>
      <Footer color={color} />
    </div>
  )
}

export default MenuContainer

// export default class MenuContainer extends React.Component {
//   state = {
//     menu: {},
//     currentMenu: 'mains'
//   }

//   validateMenuSection = (sectionName="") => {
//     if (sectionName && menus[sectionName]) {
//       return sectionName
//     }
//      else {
//        const { params } = this.props.match
//        if (params && params.id && menus[params.id]) {
//          return params.id
//        }
//      }
//     return this.state.currentMenu
//   }

//   scrollToTop = () => {
//     if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
//        window.scrollBy(0, -50);
//        requestAnimationFrame(this.scrollToTop);
//    }
//   }

//   componentDidMount() {
//     const {
//       params
//     } = this.props.match
//     const matchedMenu = this.validateMenuSection()
//     this.setState({ menu: menus[matchedMenu], currentMenu: matchedMenu })
//     this.scrollToTop();
//   }

//   updateMenuLocation = (e) => {
//     const matchedMenu = this.validateMenuSection(e.target.getAttribute('data-menuitem'))
//     this.setState({ menu: menus[matchedMenu], currentMenu: matchedMenu })
//   }

//   renderSection = (items, title="") => {
    // return (
    //   <div className="columns is-multiline" style={{paddingTop: "1rem"}}>
    //   <div className="column is-full section-menu-items">
    //       <div><h1 style={{display: "inline-block"}}>{title || this.state.currentMenu}</h1></div>
    //       {title === 'halal' && (
    //         <div>
    //           <p>At Botafogo, we are able to provide our guests halal alternatives to some of our meats with prior notice, at no extra charge. </p>
    //           <p>We require 48 hours notice to ensure we are able to source suitable products. </p>
    //           <p>Please note, although the meat itself will be halal, we cannot claim to operate a food preparation environment that follows halal guidelines. Our suppliers are all certified, however we do not always source our meats from the same supplier. </p>
    //           <p>Should you require any further details please don't hesitate in contact us</p>
    //         </div>
    //       )}
    //   </div>
    //     {
    //       items ? items.map((item, i) => {
    //         return (
              // <FadeEffect key={i} className="column is-half">
              //     <Card item={item} backgroundColor={menus[this.state.currentMenu].color} />
              // </FadeEffect>
    //         )
    //       }) : null
    //     }
    //   </div>
//     )
//   }

//   renderSections = menu => {
//     return menu.children.map((child, i) => {
//       return (
        // <div key={i} className="section-menu-items-multi">
        //   {this.renderSection(menu.items[child], child)}
        // </div>
//       )
//     })
//   }

//   renderMenus = menu => {
//     return menu.children.length > 0 ? this.renderSections(menu) : this.renderSection(menu.items);
//   }


//   render() {
//     const {
//       currentMenu, menu
//     } = this.state

//     return (
//       <div className={`content is-${this.state.currentMenu}-menu`}>
//         <Home {...this.state} />
//         <Section name="menus" className="wood-background" style={{marginTop:"12px"}}>
//           <div className="container wood-background">
//             <div className="columns is-desktop">
//               <div className="column is-one-third side-menu">
//                 {
//                   Object.keys(menus).map((menuItem, i) => {
//                     return (
//                       <Link
//                         to={`/menus/${menuItem}`}
//                         key={i}
//                         data-menuitem={menuItem}
//                         replace
//                         onClick={this.updateMenuLocation}
//                         style={{textTransform: 'capitalize'}}
//                         className={`button is-danger has-shadow ${menuItem === currentMenu ? 'is-active':''}`}>
//                         {menuItem === 'sides' ? 'Included sides' : menuItem}
//                       </Link>
//                     )
//                   })
//                 }
//                 <Prices className="is-hidden-mobile" />
//               </div>
//               <div className="column">
//                 {
//                   this.renderMenus(menus[currentMenu])
//                 }
//               </div>
//             </div>
//           </div>
//         </Section>
//         <Footer color={menus[currentMenu].color} />
//       </div>
//     )
//   }
// }
