import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import MenuContainer from '../containers/MenuContainer/MenuContainer';

const Menus = ({ pageResources = {} }) => {
  const frontmatter = pageResources?.json?.data?.allMarkdownRemark?.edges[0]?.node?.frontmatter

  return (
    <>
      <Helmet>
        <title>{frontmatter?.title}</title>
        <meta name="description" content={frontmatter?.description} />
      </Helmet>
      <MenuContainer classNames={`is-restaurant-menu is-${frontmatter?.menuType}`} {...frontmatter} />
    </>
  )
}

Menus.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Menus

export const pageQuery = graphql`
  query MyQuery($menuType: String) {
    allMarkdownRemark(filter: {frontmatter: {menuType: {eq: $menuType }}}) {
      edges {
        node {
          frontmatter {
            title
            description
            menuType
            color
            headings
            sections {
              name
              description
              items {
                name
                description
                price
                image {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
