import React from 'react'
import PropTypes from 'prop-types'
import Section from '../Section'

import NewsletterForm from '../NewsletterForm'

import './Footer.scss'
import OpeningHours from '../OpeningHours'

export default class Footer extends React.Component {

  static defaultProps = {
    color: "#692b90"
  }

  static propTypes = {
    color: PropTypes.string
  }

  render() {
    const { color } = this.props
    return (
      <Section name="menu-footer" className={`hero ${this.props.className}`} style={{backgroundColor: color}}>
          <div className="container">
            <div className="columns is-desktop">

              <div className="column">
                <div className="contact-info">
                  <h4 className="is-heading">Find Us</h4>
                  <p>37 John William Street</p>
                  <p>Huddersfield HD1 1BL</p>
                  <p>Tel: <a href="tel:+441484535440">01484 535 440</a></p>
                  <p>E-mail: <a href="mailto:info@botafogo.co.uk">info@botafogo.co.uk</a></p>
                </div>
              </div>
              <div className="column">
                <div className="opening-hours">
                  <OpeningHours />
                </div>
              </div>
              <div className="column">
                <div className="social-media">
                  <h4 className="is-heading">Social Media</h4>
                    <a
                      href="https://www.instagram.com/BotafogoGrill"
                      target="_blank"><i className="fa fa-instagram" /></a>
                  <a
                    href="https://www.facebook.com/BotafogoHuddersfield"
                    target="_blank"><i className="fa fa-facebook" /></a>
                  <a href="https://twitter.com/BotafogoGrill"
                    target="_blank"><i className="fa fa-twitter" /></a>
                  <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g190748-d3619108-Reviews-Botafogo_Brazilian_Grill-Huddersfield_West_Yorkshire_England.html" target="_blank"><i className="fa fa-tripadvisor" /></a>
                </div>
              </div>
            </div>
          </div>
      </Section>
    )
  }
}
